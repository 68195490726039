const HandleIds = {
  Achievements: 'achievements',
  App: 'app',
  Captions: 'captions',
  Comments: 'comments',
  Discovery: 'discovery',
  DiscoveryPublic: 'discovery-public',
  DiscoveryFormNew: 'discovery-form-new',
  DiscoveryFormUpdate: 'discovery-form-update',
  Group: 'groups-groupID',
  GroupFormNew: 'group-form-new',
  GroupFormUpdate: 'group-form-update',
  Groups: 'groups',
  GroupsMembersTab: 'groups-membersTab',
  GroupsTopicsTab: 'groups-topicsTab',
  Home: 'home',
  MemberResponsePage: 'member-response-page',
  Mixtape: 'mixtape',
  MixtapeFormNew: 'mixtape-form-new',
  MixtapeFormUpdate: 'mixtape-form-update',
  Mixtapes: 'mixtapes',
  MixtapeLogin: 'mixtape-login',
  MixtapesResponse: 'mixtapes-response',
  MyVideos: 'my-videos',
  MyVideosResponseId: 'my-videos-response',
  PrivateShare: 'private-share',
  QRBadges: 'qr-badges',
  QRToken: 'qr-token',
  Root: 'root',
  Settings: 'settings',
  SettingsAccount: 'settings-account',
  SettingsDefaultTopicSettings: 'settings-default-topic-settings',
  SettingsDiscoveryProfile: 'settings-discovery-profile',
  SettingsIntegrations: 'settings-integrations',
  TopicFormNew: 'topic-form-new',
  TopicFormUpdate: 'topic-form-update',
  TopicResponse: 'topic-response',
  TopicResponses: 'topic-responses',
  Topic: 'topics-topicID',
  UnsubscribeMarketing: 'unsubscribe-marketing',
  Vanity: 'vanity',
  VanityRTJ: 'vanity-rtj',
} as const;

export default HandleIds;
